import React from 'react';
import { graphql } from 'gatsby';

import Slider from "react-slick";
import './index.css';
import './responsiveHome.css';

// Clients
import PilotPlanner from '../images/clients/pilot_planner.png';
import Accointing from '../images/clients/accointing.svg';
import Confidential from '../images/clients/confidential.png';
import Dearest from '../images/clients/dearest.svg';
import Fask from '../images/clients/fask.png';
import HumanUnited from '../images/clients/human_united.svg';
import Fidola from '../images/clients/fidola.svg';
import SevenBridges from '../images/clients/7bridges.svg';
import DearestLife from '../images/clients/dearest_life.svg';
// Recommendations
import BenSteinberg from '../images/recommendations/ben_steinberg.jpeg';
import GoranMladenovic from '../images/recommendations/goran_mladenovic.png';
import RalucaDiana from '../images/recommendations/raluca_diana.jpg';
import LukasWeisheit from '../images/recommendations/lukas_weisheit.jpeg';
// Partners
import Povio from "../images/partners/povio.webp"
import HeliCentre from '../images/partners/heli_centre.png';
import ChangingDigital from '../images/partners/changing_digital.svg';
import OrangeMelons from '../images/partners/orange_melons.webp';
import RockApps from '../images/partners/rock_apps.svg';
import Proxify from '../images/partners/proxify.svg';
import UpStack from '../images/partners/upstack.png';
import UpStackTwo from '../images/partners/upstack_2.svg';
import RemoteMore from '../images/partners/remote_more.svg';
// Services
import Nest from "../images/services/nest.svg"
import Next from "../images/services/next.png"
import ReactLogo from "../images/services/react.png"
import ReactNative from "../images/services/react_native.png"
import Firebase from "../images/services/firebase.png"

function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const upstack = [
  {

    picture: BenSteinberg,
    name: "Ben Steinberg",
    title: (<p>IB Equities - PM & CSM @ <b>J.P. Morgan</b> (2005-2021)<br />Chief Financial Officer @ <b>UpStack</b></p>),
    titleStatic: (<p>IB Equities - PM & CSM at <b>J.P. Morgan</b>, Chief Financial Officer at <b>UpStack</b></p>),
    testimonial: `I have had the pleasure of working with Coetic X on several projects as the CFO at UpStack. <br/><br />
    In these projects, Coetic X has consistently demonstrated their expertise in frontend development, particularly in the fintech industry. They have a deep understanding of the latest technologies and trends, and they are able to quickly and efficiently translate business requirements into high-quality code.
    <br /><br />On one project, Coetic X was responsible for the frontend development of a tenant management system for a large fintech company. They successfully implemented all of the required features, including tax automation and ingestion. The system was delivered on time and within budget, and it has been very well-received by the client.
    <br /><br />On another project, Coetic X was responsible for the frontend development of a payment systems automation system for a small fintech startup. They worked closely with the business team to understand the requirements, and they delivered a system that met all of the client’s needs. The system has been in production for over a year and has been performing flawlessly.
    <br /><br />In addition to their technical skills, Coetic X is also a pleasure to work with. They are highly motivated and results-oriented, and they are always willing to go the extra mile. They are also good team players and always willing to help out their colleagues.
    <br /><br />I have no doubt that Coetic X would be a valuable asset to your projects. They are a highly skilled and experienced team who would be a great asset to any company.
    <br /><br />`,
    testimonials: [
      // `I have had the pleasure of working with Coetic X on several projects as the CFO at UpStack.`,
      `Coetic X has consistently demonstrated their expertise in frontend development, particularly in the fintech industry.`,
      `They have a deep understanding of the latest technologies and trends, and they are able to quickly and efficiently translate business requirements into high-quality code.`,
      // `Coetic X was responsible for the frontend development of a tenant management system for a large fintech company. They successfully implemented all of the required features, including tax automation and ingestion. The system was delivered on time and within budget, and it has been very well-received by the client.`,
      // `Coetic X was responsible for the frontend development of a payment systems automation system for a small fintech startup. They worked closely with the business team to understand the requirements, and they delivered a system that met all of the client’s needs. The system has been in production for over a year and has been performing flawlessly.`,
      // `In addition to their technical skills, Coetic X is also a pleasure to work with. They are highly motivated and results-oriented, and they are always willing to go the extra mile. They are also good team players and always willing to help out their colleagues.`,
      `They are highly motivated and results-oriented, and they are always willing to go the extra mile.`,
      `I have no doubt that Coetic X would be a valuable asset to your projects. They are a highly skilled and experienced team who would be a great asset to any company.`
    ]
    // project: (<p><b>Fintech - Taxes Automation</b> <br/> <b>Fintech - Payment Automation Platform</b></p>),
  },
  {
    picture: RalucaDiana,
    name: "Raluca Diana L.",
    title: (<p>Success Manager @ <b>UpStack</b></p>),
    titleStatic: (<p>Success Manager at <b>UpStack</b></p>),
    testimonial: `I worked with Coetic X on several projects as a Success Manager while at Upstack.
    <br /><br />
    The entire collaboration was a truly enjoyable experience.
    <br /><br />
    My role served as liaison between Client-Developer-UpStack, constantly monitoring the work of the engineers within the project and gathering feedback from all parties involved.
    <br /><br />
    One of the projects we worked on involved fintech product development for tax automation. The client sought assistance with a significant aspect of product development related to tenant management, which Coetic X efficiently handled.
    <br /><br />
    Coetic X's responsibilities were primarily focused on Frontend development for the platform using NextJS. They collaborated with the taxes team on the ingestion side and country-specific tax management. They also worked closely with the project team lead to implement tenant-based authorization management across the platform and ensure seamless collaboration between teams. Additionally, Coetic X implemented accounting features throughout the platform.
    <br /><br />
    Coetic X's involvement played an important role in enabling the tax team to successfully deliver highly intricate features, further enhancing the project's success. Their communication skills were consistently rated as excellent by the client, and tasks were delivered promptly and effectively.
    <br /><br />
    From Upstack's perspective, it was a pleasure collaborating with the team. Always responsive, team players with proactive communication, efficiently set to meet and exceed our customer's business goals.
    <br /><br />
    I believe they bring value to any project and would jump at the occasion to work with them again in the future.`,
    testimonials: [
      `The entire collaboration was a truly enjoyable experience.`,
      `The client sought assistance with a significant aspect of product development related to tenant management, which Coetic X efficiently handled.`,
      `Coetic X's involvement played an important role in enabling the tax team to successfully deliver highly intricate features, further enhancing the project's success.`,
      `Their communication skills were consistently rated as excellent by the client, and tasks were delivered promptly and effectively.`,
      `From Upstack's perspective, it was a pleasure collaborating with the team. Always responsive, team players with proactive communication, efficiently set to meet and exceed our customer's business goals.`,
      `I believe they bring value to any project and would jump at the occasion to work with them again in the future.`
    ]
    // project: (<p><b>Fintech - Taxes Automation</b></p>),
  },
]


const testimonials = [
  // {
  //   picture: "https://scontent.cdninstagram.com/v/t51.2885-19/369413983_304297825471540_1215247936930278229_n.jpg?stp=dst-jpg_s100x100&_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=uPdEguhl3FEAX8Y7qkk&_nc_ad=z-m&_nc_cid=1439&_nc_ht=scontent.cdninstagram.com&oh=00_AfDAmcBEQTcUP5wG7KarSfvnEJs8wPfQvMxRVS-1xTSy2Q&oe=64F0E968",
  //   name: "Fidan Fejza",
  //   title: (<p>Kamarjer @ <b>Fidola</b></p>),
  //   testimonial: `Un punoj prej zorit, qa po kqyr ti?`,
  //   project: (<p><b>Construction Website</b></p>),
  // },
  {
    picture: LukasWeisheit,
    name: "Lukas Weisheit",
    // title: (<p>COO @ <a href="https://www.hellodearest.io/" target="_blank">Dearest</a></p>),
    titleStatic: (<p>Chief Operating Officer at <a href="https://www.hellodearest.io/" target="_blank">Dearest</a></p>),
    testimonials: [
      `I can wholeheartedly recommend working with Coetic X for their exceptional work in developing our mobile app.`,
      `During my time as COO at Dearest, I had the pleasure of working closely with their team, and I was truly impressed with the professionalism, expertise, and dedication they brought to the project.`,
      `Arbër took on the responsibility of managing the technical aspects of our app development, and his proficiency in using React Native with TypeScript exceeded our expectations in terms of functionality, user experience, and performance.`,
      `Throughout the development process, his team maintained open communication, keeping us informed of progress, addressing any concerns promptly, and actively seeking our input.`,
      `Their collaborative approach, combined with a strong commitment to meeting deadlines, made the entire development journey a positive and productive experience.`,
      `If you are seeking a team that can turn your vision into a reality with precision and expertise, Coetic X is the ideal choice.`
    ],
    testimonial: `I can wholeheartedly recommend working with Coetic X for their exceptional work in developing our mobile app. During my time as COO at Dearest, I had the pleasure of working closely with their team, and I was truly impressed with the professionalism, expertise, and dedication they brought to the project.
<br/><br/>
    Arbër took on the responsibility of managing the technical aspects of our app development, and his proficiency in using React Native with TypeScript exceeded our expectations in terms of functionality, user experience, and performance.
    <br/><br/>
    Throughout the development process, his team maintained open communication, keeping us informed of progress, addressing any concerns promptly, and actively seeking our input. Their collaborative approach, combined with a strong commitment to meeting deadlines, made the entire development journey a positive and productive experience.
    <br/><br/>
    If you are seeking a team that can turn your vision into a reality with precision and expertise, Coetic X is the ideal choice.`,
  },
  // ...upstack,

  {
    picture: GoranMladenovic,
    name: "Goran Mladenović",
    title: (<p>Head of Matching & Freelance Community @ <a href="https://poviolabs.com/" target="_blank">Povio</a></p>),
    titleStatic: (<p>Head of Matching & Freelance Community at <a href="https://poviolabs.com/" target="_blank">Povio</a></p>),
    testimonials: [
      `We have had the privilege of collaborating with Coetic X and Arber for a while now, and we consistently find ourselves impressed by the high level of professionalism they exhibit.`,
      `Their ability to establish clear expectations, maintain seamless communication, and be readily available when needed is truly commendable.`,
      `The quality of their deliverables has consistently exceeded our expectations, and we have never encountered any issues in our interactions with them.`,
      // `We look forward to the opportunity to work with them again at any time.`
    ],
    testimonial: `We have had the privilege of collaborating with Coetic X and Arber for a while now, and we consistently find ourselves impressed by the high level of professionalism they exhibit. Their ability to establish clear expectations, maintain seamless communication, and be readily available when needed is truly commendable. Furthermore, the quality of their deliverables has consistently exceeded our expectations, and we have never encountered any issues in our interactions with them. We look forward to the opportunity to work with them again at any time.`,
  },
  upstack[1],
  upstack[0],
];

const clients = {
  povio: <img style={{ height: 21 }} src={Povio} alt="Povio" />,
  changingDigital: <img style={{ height: 19, opacity: 0.9 }} src={ChangingDigital} alt="Changing Digital" />,
  dearestLife: <img style={{ height: 23 }} src={DearestLife} alt="Dearest.Life" />,
  orangeMelons: <img style={{ opacity: 0.9 }} src={OrangeMelons} alt="OrangeMelons" />,
  rockApps: <img style={{ opacity: 0.9, marginLeft: 5, marginRight: 5 }} src={RockApps} alt="RockApps" />,
  accointing: <img style={{ filter: 'invert(1)' }} src={Accointing} alt="Accointing" />,
  upstack: <img style={{ height: 19, filter: 'grayscale(1) invert(1)' }} src={UpStack} alt="UpStack" />,
  dearest: <img style={{ height: 17, objectFit: 'contain' }} src={Dearest} alt="Dearest" />,
  proxify: <img style={{ height: 21, opacity: 0.9 }} src={Proxify} alt="Proxify" />,
  remoteMore: <img style={{ height: 18, objectFit: 'contain' }} src={RemoteMore} alt="RemoteMore" />
}

const projectsSliderSettings = {
  lazyLoad: true,
  dots: true,
  arrows: false,
  accessibility: true,
  adaptiveHeight: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 24000,
  slidesToShow: 1,
  autoplay: false,
  slidesToScroll: 1,
  appendDots: (dots) => {
    return (
      <div>
        <ul>
          {dots.map((item, index) => {
            return (
              <li style={{ height: 36, width: 36, display: 'inline-flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} key={index}>
                {item.props.children}
                <img
                  style={{
                    height: 36, width: 36,
                    borderRadius: '50%',
                    position: 'absolute',
                    objectFit: "cover",
                    zIndex: 1,
                    opacity: item.props.className == 'slick-active' ? 1 : 0.7,
                    boxShadow: item.props.className == 'slick-active' ? '0px 0px 7px 2px rgb(21 58 69 / 60%)' : 'unset'
                  }}
                  src={testimonials[index].picture}
                />
              </li>
            );
          })}
        </ul>
      </div>
    )
  },
  // height: 500,
};
const HomePage = () => {
  return (
    <div className="header-container">
      <div className="background-image">
        <div className="container">
          <div className="h-100 d-flex flex-column justify-content-center">
            <h1 className="header-title">
              We build the future! We turn ideas to reality!
              {/* Where the code <br /> ethic prospers! */}
            </h1>
            <p className="header-description">
              {/* We build the future! */}
              Where the code ethic prospers!
            </p>
            <a className="header-button-text" href="#services">
              What we do
            </a>
          </div>
        </div>
      </div>

      <div className="partners">
        <div className="container">
          <div className="partners-container">
            <div>
              <p className="partners-home-title">Our Partners</p>
            </div>

            <div className="partners-home-icons">
              <a href="https://poviolabs.com/" target="_blank">
                <img
                  style={{ width: 83, height: 34, objectFit: 'contain' }}
                  src={Povio}
                  alt="Povio"
                ></img>
              </a>
              {/* <img  style={{width:83, height:34}} src={FaskLogo} alt='faskImg'></img> */}
              {/* https://d1eipm3vz40hy0.cloudfront.net/images/Customer+Stories+/UpStack/Upstack-Logo.png */}

              <a href="https://changingdigital.com/" target="_blank">
                <img
                  style={{ width: 200, height: 50, opacity: 0.9, objectFit: 'contain' }}
                  src={ChangingDigital}
                  alt="Changing Digital"
                ></img>
              </a>
              {/* <a  href="https://upstackhq.com/" target="_blank">
                <img style={{ height: 64, opacity: 0.75, objectFit:'contain' }} src={"https://orangemelons.com/hs-fs/hubfs/logo-1.png?width=170&height=128&name=logo-1.png"} alt="helicenterImg" />
              </a> */}


              <a href="https://upstackhq.com/" target="_blank">
                <img style={{ width: 130, height: 28, opacity: 0.9, objectFit: 'contain' }} src={UpStackTwo} alt="UpStack" />
              </a>

              {/* <a  href="https://thehelicoptercentre.com/" target="_blank">
                <img style={{ width: 180, height: 50, opacity: 1, objectFit:'contain' }} src={HeliCentre} alt="helicenterImg" />
              </a> */}
              {/* <img  style={{width:107, height:34}} src={"https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR3Iy-4dpNoStCB5Zucn1O5gGWmEcbGu_SkXwHYPo2HBO1ZnWls"} className='oda' alt='odaImg'></img> */}
              {/* <img src={Fidola} alt='fidolaImg'></img> */}
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container">
          <div className="first-main-container">
            <div className="first-container">
              <p className="meet-team-text">About us</p>
              <h1 className="about-us-paragraph">
                We transform your ideas through our most deliberate engineering services, providing highest quality & efficiency!
                <br />
                <br />
              </h1>
              <div className="about-us-line"></div>
            </div>
            <div className="second-container">
              <img
                src={
                  'https://images.unsplash.com/photo-1520583457224-aee11bad5112?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3465&q=80'
                }
                className="second-container-img"
                alt="second-container-img"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="partners">
        <div className="partners-positioner" id="services"></div>
        <div className="container">
          <div className="partners-container">
            <div>
              <p className="partners-home-title">Our Services</p>
            </div>

            <div className="partners-home-icons">
              <a style={{ marginRight: 30 }} href="https://nestjs.com/" target="_blank">
                <img style={{ width: 35, height: 34 }} src={Nest} alt="NestJS" />
              </a>
              <a style={{ marginLeft: 30, marginRight: 30 }} href="https://react.dev/" target="_blank">
                <img style={{ width: 77, height: 26 }} src={ReactLogo} alt="React" />
              </a>
              {/* <img  style={{width:83, height:34}} src={FaskLogo} alt='faskImg'></img> */}
              <a style={{ marginLeft: 20, marginRight: 20 }} href="https://nextjs.org/" target="_blank">
                <img
                  style={{ width: 83, height: 50, filter: 'invert(1)' }}
                  src={Next}
                  alt="NextJS"
                />
              </a>
              <a style={{ marginLeft: 20, marginRight: 20 }} href="https://reactnative.dev/" target="_blank">
                <img
                  style={{ width: 116, height: 40, opacity: 0.9 }}
                  src={ReactNative}
                  alt="React Native"
                />
              </a>
              {/* <a style={{ marginLeft: 20, marginRight: 20 }} href="https://thehelicoptercentre.com/" target='_blank'>
          <img style={{ width: 35, height: 35}} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/1200px-GraphQL_Logo.svg.png"} alt='GraphQL' />
        </a> */}

              <a style={{ marginLeft: 0 }} href="https://firebase.google.com/" target="_blank">
                {/* <img style={{ width: 35, height: 35}} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/1200px-GraphQL_Logo.svg.png"} alt='GraphQL' /> */}
                <img style={{ width: 60, height: 45 }} src={Firebase} alt="Firebase" />
              </a>
              {/* <img  style={{width:107, height:34}} src={"https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR3Iy-4dpNoStCB5Zucn1O5gGWmEcbGu_SkXwHYPo2HBO1ZnWls"} className='oda' alt='odaImg'></img> */}
              {/* <img src={Fidola} alt='fidolaImg'></img> */}
            </div>
            <p className="partners-home-more">and more...</p>
          </div>
        </div>
      </div>

      {/* <div className="second-main-container">
        <div className="container">
          <p className="cards-title">Our Services</p>
          <div className="cards">
            <div className="inner-card">
              <div className="inner-card-icon">
                <img src="https://docs.nestjs.com/assets/logo-small.svg" alt='logo' />
              </div>
              <div className="inner-card-text">
                <h5 className="inner-card-tittle">NestJS</h5>
                <p className="inner-card-paragraph">We ensure building highly scalable and testable applications. </p>
              </div>
            </div>
            <div className="inner-card">
              <div className="inner-card-icon">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/React.svg/1200px-React.svg.png" alt='nestJs-icon' />
              </div>
              <div className="inner-card-text">
                <h5 className="inner-card-tittle">React</h5>
                <p className="inner-card-paragraph">
                  Hire a highly efficient React Engineer with top notch experience and scale your team as needed.
                </p>
              </div>
            </div>
            <div className="inner-card">
              <div className="inner-card-icon">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1920px-React-icon.svg.png" alt='react-icon' />
              </div>
              <div className="inner-card-text">
                <h5 className="inner-card-tittle">React Native</h5>
                <p className="inner-card-paragraph">
                  Well versed <br />engineers among various industries for your <br />React Native projects.
                </p>
              </div>
            </div>
            <div className="inner-card">
              <div className="inner-card-icon">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/1200px-GraphQL_Logo.svg.png" alt='react-native-logo' />
              </div>
              <div className="inner-card-text">
                <h5 className="inner-card-tittle">GraphQL</h5>
                <p className="inner-card-paragraph">Performance is key toward achieving superior excellence!</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="testimonials-container">
        <div className="container">
          <div className='testimonial-title-box'>
            <p className="reviews-home-title">Testimonials</p>
          </div>
          <div className='testimonials'>
            {testimonials.map(({ picture, name, title: other, titleStatic: title, testimonial, testimonials, project }, i) => (

              <div className="testimonial-box" data-sal={i % 2 === 0 ? "slide-right" : "slide-left"} data-sal-duration="1000" data-sal-easing="ease">
                {/* <p className="reviews-home-title">Soon to come...</p> */}
                <div className="testimonial-image-box">
                  <img src={picture} />
                </div>
                <div className="testimonial-content">


                  {/* <p><b>Fintech - Taxes Automation</b> - through UpStack</p> */}

                  {/* <quote><span dangerouslySetInnerHTML={{ __html: testimonial }} /></quote> */}
                  <blockquote><span>{testimonials[Math.floor(Math.random() * testimonials.length)]}</span></blockquote>
                  <h3>{name} <p>-</p> {title} {project ? <>- {project}</> : null}</h3>
                </div>

                {/* <h3>Stewart Jones</h3>
                      <p>Managing Director &nbsp; | &nbsp; Head of training &nbsp; | &nbsp; Flight Examiner</p>
                      <quote>I think... Diten e mire faleminderit!</quote> */}

              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="reviews d-none">
        <div className="container">
          <div className='recommendation-title-box'>
            <p className="reviews-home-title">Recommendations</p>
          </div>

          <Slider {...projectsSliderSettings}>
            {
              testimonials.map(({ picture, name, title, testimonial, project }) => (
                <div>
                  <div className="recommendation-box">
                    {/* <p className="reviews-home-title">Soon to come...</p> */}
                    <div className="recommendation-image-box">
                      <img src={picture} />
                    </div>
                    <h3>{name}</h3>
                    {title}
                    {/* <p><b>Fintech - Taxes Automation</b> - through UpStack</p> */}

                    <quote><span dangerouslySetInnerHTML={{ __html: testimonial }} /></quote>
                    {project}
                    {/* <h3>Stewart Jones</h3>
                      <p>Managing Director &nbsp; | &nbsp; Head of training &nbsp; | &nbsp; Flight Examiner</p>
                      <quote>I think... Diten e mire faleminderit!</quote> */}
                  </div>
                </div>
              ))
            }
          </Slider>
        </div>
      </div>

      <div className="clients-home">
        <div className="clients-positioner" id="our-works"></div>
        <div className="container">
          {/* <div>
            <p className="clients-home-title">Our Works</p>
          </div> */}
          <h3 className='clients-home--year'>2024</h3>
          <div className='clients-home--items'>
            {
              [
                {
                  country: 'London, UK',
                  // industry: 'ETF Industry',
                  // title: 'Finance Media',
                  industry: 'Fintech',
                  title: 'Financial Platform',
                  // description: "Contributed to the development of a platform for the ETF industry, implementing AWS Cognito for authentication and Postmark for email workflows. Additionally, enhanced marketing automation through ActiveCampaign, improving user engagement and scalability.",
                  description: "Contributed to a financial services platform, implementing AWS Cognito for authentication and Postmark for email workflows. Enhanced marketing automation through ActiveCampaign, improving user engagement and scalability using Next.js.",
                  // description: "Contributed to a financial services platform using Next.js, implementing AWS Cognito for authentication and Postmark for email workflows. Enhanced marketing automation through ActiveCampaign, improving user engagement and scalability.",
                  nda: true,
                  client: clients.changingDigital
                },
                {
                  country: 'Zug, Switzerland',
                  industry: 'Blockchain',
                  title: 'Identity Management',
                  description: "Managed a complex React Native mobile app project, integrating third-party SDKs, implementing advanced features, upgrading versions, and resolving technical issues to enhance app functionality and user experience.",
                  nda: true,
                  client: clients.povio
                },
                {
                  country: 'London, UK',
                  industry: 'Property Tech',
                  title: 'Real Estate',
                  description: "Led a React Native mobile app project, integrating Auth0 for authentication, upgrading the existing app to new versions. Implemented Auth0 integration, resolved highly complex versioning issues, and delivered multiple upgrades to enhance app functionality and performance.",
                  nda: true,
                  client: clients.changingDigital
                },
                {
                  country: 'Berlin, Germany',
                  industry: 'Healthcare',
                  title: 'Dearest.Life',
                  description: "Overhauled an app from relationship health to healthcare, leading tech efforts in a React Native app (TypeScript) with Strapi CMS, NextJS web interface, and Hasura & GraphQL. Upgraded designs, implemented a new theme and concept, and reorganized database and data for improved functionality.",
                  client: clients.dearestLife,
                  notInCollab: true
                },
                {
                  country: 'Netherlands',
                  industry: 'Restaurant Chain',
                  title: 'Terminal POS Integration',
                  description: "Acquired specialized knowledge to meet client requirements, focusing on the intricate integration of Adyen with POS terminals. Successfully implemented robust communication protocols to ensure secure and efficient payment processing for the short-term project.",
                  nda: true,
                  // client: clients.orangeMelons
                },
              ].map(({ country, industry, title, nda, description, client, notInCollab }, i) => (
                <div className='clients-home--half three-variant' style={i < 3 ? { marginTop: ((i % 3) - 1) * 80 } : { marginTop: ((i % 3) - 1) * 80 - 100 }}>
                  <div className='clients-home--half two-variant' style={i < 2 ? { marginTop: ((i % 2) - 1) * 80 } : { marginTop: ((i % 2) - 1) * 80 - 70 }}>
                    <div className='clients-home--half one-variant' style={{ marginTop: 20 }}>
                      <div className="clients-box" data-sal="slide-down" data-sal-duration="1000" data-sal-easing="ease">
                        {/* <div className="clients-image-box">
    <img style={{ filter: 'invert(1)' }} src={Accointing} />
  </div> */}
                        <div className="clients-box-content">
                          {/* <span className="clients-box-location">
      Location - <span>Zug, Switzerland</span> 
    </span> */}
                          <span className="clients-box-location">
                            {country} -<span>&nbsp;{industry}</span>
                          </span>
                          <h3>{nda && <span className="d-inline-block d-md-none d-lg-none d-xl-none" style={{ opacity: 0, marginRight: 2 }}>- NDA</span>}{title}&nbsp;{nda && <span className="d-inline-block">- NDA</span>}</h3>
                          <p>
                            {description}
                          </p>
                          {Boolean(client) && <div className="clients-box-links">
                            {/* <a href="https://7b.coetic-x.com/" target="_blank">
        <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
      </a> */}
                            <div style={{ marginLeft: 'auto', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
                              {!notInCollab && (<span className="clients-box-location" style={{ fontWeight: 300, fontSize: 10, marginRight: 5 }}>in collaboration with</span>)}
                              {client}
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ))
            }

          </div>
          <br />
          <h3 className='clients-home--year'>2023</h3>
          <div className='clients-home--items'>
            {
              [

                {
                  country: 'Illinois, US',
                  industry: 'Music',
                  title: 'Music Documentaries',
                  description: "Managed diverse project stacks, implemented frontend features, tackled occasional DevOps challenges, and coordinated multiple interconnected projects to ensure successful delivery. Oversaw the entire system, including React, Contentful, AWS, GraphQL, and additional components, to drive project success.",
                  nda: true,
                  client: clients.povio
                },
                // {
                //   country: 'Zug, Switzerland',
                //   industry: 'Blockchain',
                //   title: 'Identity Management',
                //   description: "Managed a complex React Native mobile app project, integrating third-party SDKs, implementing advanced features, upgrading versions, and resolving technical issues to enhance app functionality and user experience.",
                //   nda: true,
                //   client: (
                //     <img
                //     style={{ width: 41, height: 17}}
                //     src={Povio}
                //     alt="Povio"
                //   />
                //   )
                // },
                {
                  country: 'Zug, Switzerland',
                  industry: 'Blockchain Taxes',
                  title: 'Accointing',
                  description: "Delivered services on crypto tax reports and calculations, highly intricate features including fee calculations, using a powerful tech stack comprising NestJS, Postgres, React, Nx and more. Established the Cypress end-to-end testing framework.",
                  // nda: true,
                  client: clients.accointing,
                  notInCollab: true
                },
                {
                  country: 'Kosovo',
                  industry: 'Restaurant Chain',
                  title: 'Ordering System',
                  description: "Led development on the implementation of a POS system, overseeing frontend implementation and creating a tool for automating project component generation. Enhanced efficiency by automating API-to-frontend code generation with strong type enforcement.",
                  nda: true,
                  client: clients.rockApps
                },
                {
                  country: 'Toronto, Canada',
                  industry: 'Virtual Try On',
                  title: 'Fashion Tech',
                  nda: true,
                  description: "Integrated an SDK with Shopify, revamped the app structure, and developed a client dashboard. Engineered a custom SDK module from scratch, tailored to specific client requirements, ensuring seamless integration with Shopify and flexibility for client adaptation.",
                },
                {
                  country: 'New York, US',
                  industry: 'E-commerce',
                  title: 'Furniture Logistics',
                  description: "Focused on frontend tasks, implementing innovative features for a New York-based logistics company specializing in second-hand sales. Successfully integrated complex frontend features, enhancing user experience and functionality.",
                  nda: true,
                  client: clients.povio
                },
                // {
                //   country: 'Netherlands',
                //   industry: 'Restaurant Chain',
                //   title: 'Terminal POS Integration',
                //   description: "Acquired specialized knowledge to meet client requirements, focusing on the intricate integration of Adyen with POS terminals. Successfully implemented robust communication protocols to ensure secure and efficient payment processing for the short-term project.",
                //   nda: true,
                //   client: (
                //     <img
                //     style={{ width: 50, height: 28, opacity: 0.9, objectFit:'contain'  }}
                //     src={'https://orangemelons.com/hs-fs/hubfs/logo-1.png?width=85&height=64&name=logo-1.png'}
                //     // alt="Povio"
                //   />
                //   )
                // },
                // {
                //   country: 'Kosovo',
                //   industry: 'Restaurant Chain',
                //   title: 'Ordering System',
                //   description: "Led development on the implementation of a POS system, overseeing frontend implementation and creating a tool for automating project component generation. Enhanced efficiency by automating API-to-frontend code generation with strong type enforcement.",
                //   nda: true,
                //   client: (
                //     <img
                //     style={{ width: 50, height: 28, opacity: 0.9, objectFit:'contain'  }}
                //     src={'https://rockapps.net/wp-content/uploads/2024/01/Favi-big-1.svg'}
                //     // alt="Povio"
                //   />
                //   )
                // },
              ].map(({ country, industry, title, nda, description, client, notInCollab }, i) => (
                <div className='clients-home--half three-variant' style={i < 3 ? { marginTop: ((i % 3) - 1) * 80 } : { marginTop: ((i % 3) - 1) * 80 - 100 }}>
                  <div className='clients-home--half two-variant' style={i < 2 ? { marginTop: ((i % 2) - 1) * 80 } : { marginTop: ((i % 2) - 1) * 80 - 70 }}>
                    <div className='clients-home--half one-variant' style={{ marginTop: 20 }}>
                      <div className="clients-box" data-sal="slide-down" data-sal-duration="1000" data-sal-easing="ease">
                        {/* <div className="clients-image-box">
    <img style={{ filter: 'invert(1)' }} src={Accointing} />
  </div> */}
                        <div className="clients-box-content">
                          {/* <span className="clients-box-location">
      Location - <span>Zug, Switzerland</span> 
    </span> */}
                          <span className="clients-box-location">
                            {country} -<span>&nbsp;{industry}</span>
                          </span>
                          <h3>{nda && <span className="d-inline-block d-md-none d-lg-none d-xl-none" style={{ opacity: 0, marginRight: 2 }}>- NDA</span>}{title}&nbsp;{nda && <span className="d-inline-block">- NDA</span>}</h3>
                          <p>
                            {description}
                          </p>
                          {Boolean(client) && <div className="clients-box-links">
                            {/* <a href="https://7b.coetic-x.com/" target="_blank">
        <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
      </a> */}
                            <div style={{ marginLeft: 'auto', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
                              {!notInCollab && (<span className="clients-box-location" style={{ fontWeight: 300, fontSize: 10, marginRight: 5 }}>in collaboration with</span>)}
                              {client}
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ))
            }

          </div>
          <br />
          <h3 className='clients-home--year'>2022</h3>
          <div className='clients-home--items'>
            {
              [
                {
                  country: 'London, UK',
                  industry: 'Supply Chains',
                  title: 'Logistics & Data Science',
                  description: "Contributed to a data science client in the UK by refactoring the entire codebase, implementing new features, resolving bugs, and optimizing code performance using React as the primary technology stack.",
                  nda: true,
                  client: clients.povio
                },
                {
                  country: 'London, UK',
                  industry: 'Fintech',
                  title: 'Payments Service',
                  description: "Contributed to an SDK specializing in payment acceptance and configuring payment workflows, collaborating closely with backend automation and implementing dynamic frontend patterns.",
                  nda: true,
                  client: clients.upstack,
                },

                {
                  country: 'Berlin, Germany',
                  industry: 'Relationship Healthcare',
                  title: 'HelloDearest',
                  description: "Led all technical aspects of the project, focusing on developing a mobile application with React Native and TypeScript. Managed content storage in Strapi CMS, constructed the web interface with NextJS, and utilized Hasura and GraphQL for backend functionality.",
                  client: clients.dearest,
                  notInCollab: true
                },
                {
                  country: 'Sweden',
                  industry: 'Nightlife App',
                  title: 'JanneApp',
                  description: "As the lead team for the mobile app project, we undertook the development of a social connection mobile app. Utilizing technologies like React Native, Expo, and Redux for robust state management, we ensured efficient and seamless functionality throughout.",
                  // nda: true,
                  client: clients.proxify
                },
                {
                  country: 'Dublin, Ireland',
                  industry: 'Fintech',
                  title: 'Global Tax Automation',
                  description: "Engineered a robust platform using Next.js for managing invoicing and automated country-based tax processes. Employed Next.js to create a structured architecture that included advanced tenant management capabilities, ensuring scalability and efficiency.",
                  nda: true,
                  client: clients.upstack
                },
                // {
                //   country: 'Kosovo',
                //   industry: 'Restaurant Chain',
                //   title: 'Ordering System',
                //   description: "Led development on the implementation of a POS system, overseeing frontend implementation and creating a tool for automating project component generation. Enhanced efficiency by automating API-to-frontend code generation with strong type enforcement.",
                //   nda: true,
                //   client: (
                //     <img
                //     style={{ width: 50, height: 28, opacity: 0.9, objectFit:'contain'  }}
                //     src={'https://rockapps.net/wp-content/uploads/2024/01/Favi-big-1.svg'}
                //     // alt="Povio"
                //   />
                //   )
                // },
              ].map(({ country, industry, title, nda, description, client, notInCollab }, i) => (
                <div className='clients-home--half three-variant' style={i < 3 ? { marginTop: ((i % 3) - 1) * 80 } : { marginTop: ((i % 3) - 1) * 80 - 100 }}>
                  <div className='clients-home--half two-variant' style={i < 2 ? { marginTop: ((i % 2) - 1) * 80 } : { marginTop: ((i % 2) - 1) * 80 - 70 }}>
                    <div className='clients-home--half one-variant' style={{ marginTop: 20 }}>
                      <div className="clients-box" data-sal="slide-down" data-sal-duration="1000" data-sal-easing="ease">
                        {/* <div className="clients-image-box">
    <img style={{ filter: 'invert(1)' }} src={Accointing} />
  </div> */}
                        <div className="clients-box-content">
                          {/* <span className="clients-box-location">
      Location - <span>Zug, Switzerland</span> 
    </span> */}
                          <span className="clients-box-location">
                            {country} -<span>&nbsp;{industry}</span>
                          </span>
                          <h3>{nda && <span className="d-inline-block d-md-none d-lg-none d-xl-none" style={{ opacity: 0, marginRight: 2 }}>- NDA</span>}{title}&nbsp;{nda && <span className="d-inline-block">- NDA</span>}</h3>
                          <p>
                            {description}
                          </p>
                          {Boolean(client) && <div className="clients-box-links">
                            {/* <a href="https://7b.coetic-x.com/" target="_blank">
        <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
      </a> */}
                            <div style={{ marginLeft: 'auto', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
                              {!notInCollab && (<span className="clients-box-location" style={{ fontWeight: 300, fontSize: 10, marginRight: 5 }}>in collaboration with</span>)}
                              {client}
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ))
            }

          </div>
          <br />
          <h3 className='clients-home--year'>2021</h3>
          <div className='clients-home--items'>
            {
              [
                {
                  country: 'Uppsala, Sweden',
                  industry: 'Healthcare App',
                  title: 'Porall - Human United',
                  description: "Managed all technical aspects of the project, developing a mobile app with React Native and TypeScript, utilizing Strapi CMS for content management, and building the web interface with Next.js. Integrated Hasura and GraphQL for backend operations, ensuring robust functionality.",
                  // nda: true,
                  client: clients.proxify
                },
                {
                  country: 'Sofia, Bulgaria',
                  industry: 'Healthcare',
                  title: 'Healthcare App',
                  description: "Contributed significantly to the team's efforts in delivering a React Native application, focusing on implementing intricate dependency workflows and ensuring robust TypeScript code for enhanced maintainability and performance.",
                  nda: true,
                  client: clients.remoteMore,
                },

                {
                  country: 'New York, US',
                  industry: 'E-commerce',
                  title: 'Sleep Industry',
                  description: "Contributed to a tech company based in NY, focusing on Next.js development, Shopify integration, feature implementation, and modernizing the codebase with updated patterns and practices.",
                  nda: true,
                  client: clients.povio
                },
                {
                  country: 'London, UK',
                  industry: 'Environmental',
                  title: 'Ode Site Checker',
                  description: "Developed a custom tool using Cypress for analyzing website performance, assessing factors like page load speed, memory usage, and environmental impact. The tool provided insights into carbon footprint and evaluated the impact of different coding practices on page processing efficiency.",
                  // nda: true,
                  client: clients.changingDigital
                },
                {
                  country: 'Colorado, US',
                  industry: 'Relationships',
                  title: 'Dating App',
                  description: "Contributed to a sophisticated dating app project featuring voice call capabilities, leveraging advanced React Native implementations. Implemented key features and applied standard React Native practices to enhance functionality and user experience.",
                  nda: true,
                  // client: (
                  //   <img
                  //   style={{ width: 75, height: 30, filter: 'grayscale(1) invert(1)', objectFit:'contain'}}
                  //   src={'https://d1eipm3vz40hy0.cloudfront.net/images/Customer+Stories+/UpStack/Upstack-Logo.png'}
                  //   alt="Povio"
                  // />
                  // )
                },
                // {
                //   country: 'Kosovo',
                //   industry: 'Restaurant Chain',
                //   title: 'Ordering System',
                //   description: "Led development on the implementation of a POS system, overseeing frontend implementation and creating a tool for automating project component generation. Enhanced efficiency by automating API-to-frontend code generation with strong type enforcement.",
                //   nda: true,
                //   client: (
                //     <img
                //     style={{ width: 50, height: 28, opacity: 0.9, objectFit:'contain'  }}
                //     src={'https://rockapps.net/wp-content/uploads/2024/01/Favi-big-1.svg'}
                //     // alt="Povio"
                //   />
                //   )
                // },
              ].map(({ country, industry, title, nda, description, client, notInCollab }, i) => (
                <div className='clients-home--half three-variant' style={i < 3 ? { marginTop: ((i % 3) - 1) * 80 } : { marginTop: ((i % 3) - 1) * 80 - 100 }}>
                  <div className='clients-home--half two-variant' style={i < 2 ? { marginTop: ((i % 2) - 1) * 80 } : { marginTop: ((i % 2) - 1) * 80 - 70 }}>
                    <div className='clients-home--half one-variant' style={{ marginTop: 20 }}>
                      <div className="clients-box" data-sal="slide-down" data-sal-duration="1000" data-sal-easing="ease">
                        {/* <div className="clients-image-box">
    <img style={{ filter: 'invert(1)' }} src={Accointing} />
  </div> */}
                        <div className="clients-box-content">
                          {/* <span className="clients-box-location">
      Location - <span>Zug, Switzerland</span> 
    </span> */}
                          <span className="clients-box-location">
                            {country} -<span>&nbsp;{industry}</span>
                          </span>
                          <h3>{nda && <span className="d-inline-block d-md-none d-lg-none d-xl-none" style={{ opacity: 0, marginRight: 2 }}>- NDA</span>}{title}&nbsp;{nda && <span className="d-inline-block">- NDA</span>}</h3>
                          <p>
                            {description}
                          </p>
                          {Boolean(client) && <div className="clients-box-links">
                            {/* <a href="https://7b.coetic-x.com/" target="_blank">
        <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
      </a> */}
                            <div style={{ marginLeft: 'auto', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
                              {!notInCollab && (<span className="clients-box-location" style={{ fontWeight: 300, fontSize: 10, marginRight: 5 }}>in collaboration with</span>)}
                              {client}
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ))
            }

          </div>
          {/* <div className="clients-box" data-sal="slide-down" data-sal-duration="1000" data-sal-easing="ease">
            <div className="clients-image-box">
              <img style={{ width: 100, opacity: 0.9 }} src={PilotPlanner} />
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Mallorca, Spain</span>
              </span>
              <h3>
                Pilot Planner <span className="d-block d-md-inline-block"><span className="d-none d-md-inline-block">-</span> Helicopter School Platform</span>
              </h3>
              <p>
              We successfully brought the project from ideation to completion, leveraging our expertise in React, React Native, Firebase, and Firestore to deliver efficient and reliable functionality, ensuring the project's vision was fully realized.
              </p>
              <div className="clients-box-links">
                <a href="https://pilot-planner.com/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
                </a>

                <a href="https://apps.apple.com/us/app/pilot-planner/id1663899565?platform-iphone"  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="MuiBox-root css-1f1l0u9 iconify iconify--ant-design" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5c-34.9-50-87.7-77.5-157.3-82.8c-65.9-5.2-138 38.4-164.4 38.4c-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8c23.8 68.2 109.6 235.3 199.1 232.6c46.8-1.1 79.9-33.2 140.8-33.2c59.1 0 89.7 33.2 141.9 33.2c90.3-1.3 167.9-153.2 190.5-221.6c-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7c-44.8 2.6-96.6 30.5-126.1 64.8c-32.5 36.8-51.6 82.3-47.5 133.6c48.4 3.7 92.6-21.2 129-63.7z"></path></svg>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.coeticx.pilotplanner"  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="MuiBox-root css-1f1l0u9 iconify iconify--ant-design" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4c-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8c-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6c-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1 1 26.9-26.9a26.97 26.97 0 0 1-26.9 26.9zm208.4 0a26.9 26.9 0 1 1 26.9-26.9a26.97 26.97 0 0 1-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path></svg>
                </a>
                <div  style={{ marginLeft:'auto',display:'inline-flex',justifyContent:'center',alignItems:'center', paddingTop:10 }}>
                  <span className="clients-box-location" style={{fontWeight:300, fontSize:10, marginRight:5}}>in partnership with</span>
                  <img
                    style={{ width: 98, height: 30}}
                    src={HeliCentre}
                    alt="HeliCentre"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clients-box" data-sal="slide-down" data-sal-duration="1000" data-sal-easing="ease">
            <div className="clients-image-box">
              <img style={{ filter: 'invert(1)' }} src={Accointing} />
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Zug, Switzerland</span>
              </span>
              <h3>Accointing by Glassnode</h3>
              <p>
                Delivered services on crypto tax reports and calculations, highly intricate features including fee calculations, using a powerful tech stack
                comprising NestJS, Postgres, React, Nx and more. Established the Cypress end-to-end testing framework.
              </p>
              <div className="clients-box-links">
                <a href="https://accointing.com/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
                </a>
              </div>
            </div>
          </div>
          <div className="clients-box">
            <div className="clients-image-box">
              <img style={{ height: 90, width: 150, opacity: 0.8}} src={SevenBridges} />
             
              
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Prishtina, Kosovo</span>
              </span>
              <h3>
                7Bridges DevTool <span className="d-block d-md-inline-block"><span className="d-none d-md-inline-block">-</span> Seamless Code Generation</span>
              </h3>
              <p>
              By defining settings and utilizing your preferred tech stack, our platform ensures that unit tests are generated with ease, following the project's established patterns. It's a straightforward and efficient approach that empowers developers to focus on coding, knowing that testing is taken care of. Embrace the simplicity of unit test generation with our platform and experience a more efficient and productive software development journey.
              </p>
              <div className="clients-box-links">
                <a href="https://7b.coetic-x.com/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
                </a>
                <div  style={{ marginLeft:'auto',display:'inline-flex',justifyContent:'center',alignItems:'center', paddingTop:10 }}>
                  <span className="clients-box-location" style={{fontWeight:300, fontSize:10, marginRight:5}}>in collaboration with</span>
                  <img
                    style={{ width: 41, height: 17}}
                    src={Povio}
                    alt="Povio"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clients-box">
            <div className="clients-image-box">
              <img
                style={{ filter: 'invert(1) grayscale(1)', opacity: 0.3 }}
                src={Confidential}
              />
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Canada</span>
              </span>
              <h3>
                Fashion Tech <span className="d-block d-md-inline-block"><span className="d-none d-md-inline-block">-</span> Non-disclosed Agreement</span>
              </h3>
              <p>
                Implemented an SDK with a Shopify integration for the client customers, restructured the app and aded a few mobile features. Implemented an
                internal dashboard for managing client data.
              </p>
            </div>
          </div>
          <div className="clients-box">
            <div className="clients-image-box">
              <img style={{opacity: 0.9}} src={Dearest} />
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Berlin, Germany</span>
              </span>
              <h3>
                Dearest GmbH <span className="d-block d-md-inline-block"><span className="d-none d-md-inline-block">-</span> Relationship Health</span>
              </h3>
              <p>
                Responsible for managing the technical aspects of the project. Our role involved developing the mobile application using React Native with
                TypeScript, storing the app's content in Strapi CMS and building the web interface using NextJS and using Hasura and GraphQL for backend.
              </p>
              <div className="clients-box-links">
                <a href="https://www.hellodearest.io/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
                </a>

                <a href="https://apps.apple.com/rs/app/dearest-love-healthier/id1583462259"  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="MuiBox-root css-1f1l0u9 iconify iconify--ant-design" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5c-34.9-50-87.7-77.5-157.3-82.8c-65.9-5.2-138 38.4-164.4 38.4c-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8c23.8 68.2 109.6 235.3 199.1 232.6c46.8-1.1 79.9-33.2 140.8-33.2c59.1 0 89.7 33.2 141.9 33.2c90.3-1.3 167.9-153.2 190.5-221.6c-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7c-44.8 2.6-96.6 30.5-126.1 64.8c-32.5 36.8-51.6 82.3-47.5 133.6c48.4 3.7 92.6-21.2 129-63.7z"></path></svg>
                </a>
                
              </div>
            </div>
          </div>

          <div className="clients-box">
            <div className="clients-image-box">
              <img style={{ opacity: 0.9 }} src={Fask} />
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Prishtina, Kosovo</span>
              </span>
              <h3>FASK <span className="d-block d-md-inline-block"><span className="d-none d-md-inline-block">-</span> Federata e Auto Sporteve të Kosovës</span></h3>
              <p>
             
              As a company, we created an integrated dashboard that revolutionized licensing compliance and management for competitors and officials. Our solution simplifies the oversight of licenses, including renewals and compliance checks, while offering powerful reporting and analytics tools for data-driven decision-making. This project highlights our commitment to tailored efficiency and enhanced decision-making for our clients.
              </p>
              <div className="clients-box-links">
                <a href="https://fask.coetic-x.com/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
                </a>
              </div>
            </div>
          </div>
          <div className="clients-box">
            <div className="clients-image-box">
              <img style={{ height: 90, width: 150, opacity: 0.8 }} src={HumanUnited} />
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Uppsala, Sweden</span>
              </span>
              <h3>
                Porall - Human United <span className="d-block d-md-inline-block"><span className="d-none d-md-inline-block">-</span> Healthcare App</span>
              </h3>
              <p>
                Responsible for managing all technical aspects of the project. My role involved developing the mobile application using React Native with
                TypeScript, storing the app's content in Strapi CMS and building the web interface using NextJS and using Hasura and GraphQL for backend.
              </p>
              <div className="clients-box-links">
                <a href="https://humanunited.com/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
                </a>

                <a href="https://apps.apple.com/rs/app/human-united/id1549504543"  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="MuiBox-root css-1f1l0u9 iconify iconify--ant-design" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5c-34.9-50-87.7-77.5-157.3-82.8c-65.9-5.2-138 38.4-164.4 38.4c-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8c23.8 68.2 109.6 235.3 199.1 232.6c46.8-1.1 79.9-33.2 140.8-33.2c59.1 0 89.7 33.2 141.9 33.2c90.3-1.3 167.9-153.2 190.5-221.6c-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7c-44.8 2.6-96.6 30.5-126.1 64.8c-32.5 36.8-51.6 82.3-47.5 133.6c48.4 3.7 92.6-21.2 129-63.7z"></path></svg>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.porall.prevens"  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="MuiBox-root css-1f1l0u9 iconify iconify--ant-design" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4c-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8c-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6c-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1 1 26.9-26.9a26.97 26.97 0 0 1-26.9 26.9zm208.4 0a26.9 26.9 0 1 1 26.9-26.9a26.97 26.97 0 0 1-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4c30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path></svg>
                </a>
                <div  style={{ marginLeft:'auto',display:'inline-flex',justifyContent:'center',alignItems:'center', paddingTop:10 }}>
                  <span className="clients-box-location" style={{fontWeight:300, fontSize:10, marginRight:5}}>in collaboration with</span>
                  <img
                    style={{ width: 66, height: 15, opacity: 0.8}}
                    src={Proxify}
                    alt="Proxify"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clients-box">
            <div className="clients-image-box">
              <img style={{ height: 90, width: 150, opacity: 0.8, filter: 'invert(1) grayscale(1)' }} src={Fidola} />
            </div>
            <div className="clients-box-content">
              <span className="clients-box-location">
                Location - <span>Prishtina, Kosovo</span>
              </span>
              <h3>
                Fidola <span className="d-block d-md-inline-block"><span className="d-none d-md-inline-block">-</span> Construction Website</span>
              </h3>
              <p>
              In collaboration with Fidola, a construction company founded in Pristina in 1993, we brought their vision to life. Our partnership delivered a portfolio website that beautifully showcases Fidola's rich history and dedication to excellence, adhering to international standards and tailored solutions. Like Fidola's growth, our collaboration embodies a commitment to innovation and success.
              </p>
              <div className="clients-box-links">
                <a href="https://fidola.coetic-x.com/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 32 32" ><g transform="scale(0.03125 0.03125)"><path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z"></path></g></svg>
                </a>
              </div>
            </div>
          </div> */}

        </div>
      </div>

      {/* <div className="third-main-container">
        <div className='container'>
          <p className="cards-title">Our Clients</p>
          <div className='clients'>
            <div className="client client--1">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1605792657660-596af9009e82?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2002&q=80" alt='blockchain-img' />
              </div>
              <div className='client-content'>
                <h2>Blockchain</h2>
                <p>Portfolio Management, Accounting Services & Optimizations</p>
              </div>
            </div>
            <div className="client client--2">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1609709295948-17d77cb2a69b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80" alt='fashion-img' />
              </div>
              <div className='client-content'>
                <h2>Fashion Industry</h2>
                <p>Online Marketplace AI Modeling</p>
              </div>
            </div>
            <div className="client client--3">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1573205485246-ee99bb898ff6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" alt='e-commerce-img' />
              </div>
              <div className='client-content'>
                <h2>E-commerce</h2>
                <p>Online Marketplace & Logistics</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="fourth-main-container">
        <div className="container">
          <div>
            <p className="industries-home-title">Industries</p>
          </div>
          <div className="industries-box">
            {[
              { icon: 'airplanemode_active', title: 'Aviation' },
              { icon: 'account_balance', title: 'Accounting' },
              { icon: 'currency_bitcoin', title: 'Blockchain' },
              { icon: 'emoji_people', title: 'Fashion' },
              // {icon: 'psychology', title:"Relationship Health"},
              { icon: 'attach_money', title: 'Fintech' },
              { icon: 'favorite_border', title: 'Dating' },
              { icon: 'health_and_safety', title: 'Healthcare' },
              { icon: 'liquor', title: 'Preventing Addiction' },
              { icon: 'nightlife', title: 'NightLife' },
              { icon: 'local_shipping', title: 'Logistics' },
            ].map(({ icon, title }) => (
              <div className="industry-box">
                <div>
                  <span className="material-icons">{icon}</span>
                </div>
                <h4>{title}</h4>
              </div>
            ))}
          </div>
          {/* <div className='clients'>

            <div className="client client--1">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1606185540834-d6e7483ee1a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" alt='logistic-img' />
              </div>
              <div className='client-content'>
                <h2>Logistics</h2>
                <p>AI Powered Logistics Platform - Shipments</p>
              </div>
            </div>

            <div className="client client--2">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2426&q=80" alt='tax-img' />
              </div>
              <div className='client-content'>
                <h2>Tax Automation</h2>
                <p>Platform for handling accounting across the globe</p>
              </div>
            </div>

            <div className="client client--3">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1587906697341-bfbde76785c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1926&q=80" alt='payment-img' />
              </div>
              <div className='client-content'>
                <h2>Payment Services</h2>
                <p>E-commerce payment automation</p>
              </div>
            </div>

            <div className="client client--4">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1434494878577-86c23bcb06b9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" alt='healthcare-img' />
              </div>
              <div className='client-content'>
                <h2>Healthcare</h2>
                <p>AI for bettering healthcare decisions</p>
              </div>
            </div>

            <div className="client client--5">
              <div className='image-container'>
                <img src="https://images.unsplash.com/photo-1499728603263-13726abce5fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" alt='mental-health-img' />
              </div>
              <div className='client-content'>
                <h2>Mental Health</h2>
                <p>Treating and preventing addictions</p>
              </div>
            </div>

          </div> */}
        </div>
      </div>
    </div>
  );
};
export default HomePage;
export const query = graphql`
      query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language } }) {
        edges {
        node {
        ns
          data
      language
        }
      }
    }
  }
      `;
